@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nunito Sans';
  src: url(../public/fonts/NunitoSans-Regular.ttf);
  font-style: normal;
  font-weight: normal;
}

@media print {
  #_hj_feedback_container {
    display: none;
  }
}

html {
  scroll-padding-top: 20vh
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
#__next {
  height: 100%;
}

#launcher {
  z-index: 49 !important;
}

/* Set below to prevent dark mode inputs to be black */
input {
  background-color: #ffffff;
}

.main-container {
  margin: 0 auto;
}

@media (min-width: 768px) {
  .main-container {
    padding: 0 2rem;
  }
}

@media (max-width: 767px) {
  .mobile-padding {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.escape-container {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
}

.embla .bid-auction-card {
  width: 280px;
}

@media (min-width: 768px) {
  .embla .bid-auction-card {
    width: 274px;
  }
}

@media (min-width: 1024px) {
  .embla .bid-auction-card {
    width: 290px;
  }
}

.embla .bid-item-card {
  width: 150px;
}

.bid-item-card .item-card-padding {
  padding-right: 27px;
}

@media (min-width: 768px) {
  .embla .bid-item-card {
    width: 240px;
  }
}

.embla .marketplace-product-card {
  width: 150px;
}

@media (min-width: 768px) {
  .embla .marketplace-product-card {
    width: 240px;
  }
}

@media (min-width: 1024px) {
  .embla .marketplace-product-card {
    width: 284px;
  }
}

@media (min-width: 1444px) {
  .main-container {
    padding: 0 calc((100vw - 1444px) / 2);
  }
}

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

.aside_location_filter {
  max-width: 220px;
}

.checkbox {
  position: relative;
  left: 10px;
  width: 10px;
  height: 10px;
  border: 1px solid grey;
  border-radius: 2px;
  background: #ffffff;
  transition: 0.2s;
}

.aside_location_filter {
  max-width: 220px;
}

.social_media_icon {
  svg:hover {
    fill: #297af7;
  }
}

/* Swiper Carousel styles */

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: contain;
  max-height: 60vh;
  margin-top: 1rem;
}

.swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background: none;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 50px;
  box-sizing: border-box;
  padding-left: 19px !important;
}

.mySwiper .swiper-slide {
  width: 50px !important;
  height: 100%;
  opacity: 0.6;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-button-next::after {
  display: none;
}

.swiper-arrow-containter {
  width: 20px;
  background: white;
}

@media (min-width: 1280px) {
  .mySwiper {
    height: 440px;
    width: 80px !important;
    padding-left: 0px !important;
    padding-top: 20px !important;
  }

  .mySwiper .swiper-wrapper {
    width: 80px !important;
  }

  .mySwiper .swiper-slide {
    width: 80px !important;
    height: 80px !important;
  }

  .swiper-arrow-containter {
    width: 80px;
    height: 20px;
  }
  .item-detail-top {
    width: calc(100vw - 424px);
  }
}

.print-bg {
  -webkit-print-color-adjust: exact;
}

@media print {
  main {
    border: none;
  }
  #launcher {
    display: none;
  }
}

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  @apply hidden md:block;
  position: absolute;
  z-index: 10;
}

.slick-arrow.slick-prev::before,
.slick-arrow.slick-next::before {
  color: #297af7;
}

.desktop-table th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 2rem;
  background: white;
}

.desktop-table thead {
  border: 1px solid rgb(229, 231, 235);
}

